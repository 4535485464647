<script>
import { SuawMainContent, SuawTabs } from "@suaw/suaw-component-library";
import LogInForm from "../components/LogInForm.vue";
import SignUpForm from "../components/SignUpForm.vue";
export default {
  name: "SignIn",
  components: { SuawMainContent, SuawTabs, LogInForm, SignUpForm },
  data() {
    return {
      activeTabIndex: 0
    };
  },
  created() {
    // Check for the "tab" query string and set the tab accordingly
    const tab = this.$route.query.tab;
    if (tab === "signup") {
      this.activeTabIndex = 1;
    } else {
      this.activeTabIndex = 0; // Default to Login
    }
  }
};
</script>

<template>
  <SuawMainContent size="small">
    <SuawTabs v-model="activeTabIndex" :tabs="[{ label: 'Log In' }, { label: 'Sign Up' }]" tabs-type="lines" tabs-style="border">
      <template #tab-0>
        <LogInForm />
      </template>
      <template #tab-1>
        <SignUpForm />
      </template>
    </SuawTabs>
  </SuawMainContent>
</template>
